import React, { memo, useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";

import Icon from "@sellernote/_shared/src/componentsToMoveToV1/Icon";
import { INVENTORY_MANAGEMENT_KIND_MAP } from "@sellernote/_shared/src/constants/fulfillment/inventory";
import {
  TableDataListItem,
  TableRowInfoToHighlight,
} from "@sellernote/_shared/src/headlessComponents/table/useTable";
import { FULFILLMENT_COMMON_ATOMS } from "@sellernote/_shared/src/states/fulfillment/common";
import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { Overwrite } from "@sellernote/_shared/src/types/common/customUtilityTypes";
import {
  ReceivingItem,
  ReceivingPlaceItem,
} from "@sellernote/_shared/src/types/fulfillment/receiving";
import { ScannedLocation } from "@sellernote/_shared/src/types/fulfillment/scan";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import {
  changeScanModeToLocationOnFullCount,
  checkUsesManagementDate,
  convertItemListToMapBySKU,
  getManagementDateLabel,
  getWarehousingItemStatusLabel,
} from "@sellernote/_shared/src/utils/fulfillment/common";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";
import { checkForUnverifiedItem } from "@sellernote/_shared/src/utils/fulfillment/inspection";
import { checkConfirmedAsSingleLocationWarehousing } from "@sellernote/_shared/src/utils/fulfillment/receiving";
import { getHasMultiLocationWarehousing } from "@sellernote/_shared/src/utils/fulfillment/receiving";
import { getStyledSKUCount } from "@sellernote/_shared/src/utils/fulfillment/sku";
import TableForCounting from "@sellernote/_sds-v1/src/components/table/TableForCounting";

import { ConfirmModal } from "hooks/common/useConfirmModal";
import useCountForScanning from "hooks/common/useCountForScanning";
import useGetWorkerByIdFactory from "hooks/common/useGetWorkerByIdFactory";
import useCompleteWarehousingByForce from "hooks/returning/useCompleteWarehousingByForce";
import useRemoveWarehousingPersonInCharge from "hooks/returning/useRemoveWarehousingPersonInCharge";
import useResetWarehousingOfItem from "hooks/returning/useResetWarehousingOfItem";
import {
  getCounterKeyByStatus,
  getCounterKeyForMultiLocationWarehousing,
  SKUCountingForWarehousing,
} from "hooks/returning/useSKUCountingForWarehousing";
import useSKUImageInfoModal from "hooks/sku/useSKUImageInfoModal";
import { authSelectors } from "modules/auth";
import { useAppSelector } from "store";
import {
  getCanReset,
  getCurrentSKUCount,
  getHasRightToReset,
  getLocationName,
  getPersonInCharge,
} from "./utils";

import CompleteByForce from "components/CompleteByForce";
import CountForScanning from "components/CountForScanning";
import ItemStatus from "components/ItemStatus";
import PersonInCharge from "components/PersonInCharge";
import ResetCounting from "components/ResetCounting";
import SelectMultiLocation from "components/SelectMultiLocation";
import SKUInfo from "components/SKUInfo";
import SubRowIndicator from "components/SubRowIndicator";
import UnverifiedSku from "components/UnverifiedSku";

import { ScanModeForReturningWarehousing } from "..";
import Styled from "./index.styles";

interface ReturningWarehousingDetailTableItem {
  selection: React.ReactNode;
  location: React.ReactNode;
  SKUId: React.ReactNode;
  managementDate: React.ReactNode;
  count: React.ReactNode;
  personInCharge?: React.ReactNode;
  completeStatus: string;
  reset: React.ReactNode;
  completeByForce: React.ReactNode;
  selectMultiLocation: React.ReactNode;
}

type WarehousingItem = Overwrite<
  Partial<ReceivingItem>,
  { id: number; sku: { id: number }; placeItems: ReceivingPlaceItem[] }
>;

function SKUList({
  setScanMode,
  returningId,
  mainManagerId,
  detailItems,
  selectedLocation,
  rowInfoToHighlight,
  setRowInfoToHighlight,
  groupedItemIdInProgress,
  setGroupedItemIdInProgress,
  skuCounting,
  resetCountByCounterKey,
  setConfirmModal,
  resetAfterCompleteWarehousingByForce,
}: {
  setScanMode: (v: ScanModeForReturningWarehousing) => void;
  returningId: number;
  mainManagerId: number | undefined;
  detailItems: ReceivingItem[] | undefined;
  selectedLocation: ScannedLocation | undefined;
  rowInfoToHighlight: TableRowInfoToHighlight | undefined;
  setRowInfoToHighlight: (val: TableRowInfoToHighlight) => void;
  groupedItemIdInProgress: number;
  setGroupedItemIdInProgress: React.Dispatch<React.SetStateAction<number>>;
  skuCounting: SKUCountingForWarehousing;
  resetCountByCounterKey: (counterKey: string) => () => void;
  setConfirmModal: React.Dispatch<
    React.SetStateAction<ConfirmModal | undefined>
  >;
  resetAfterCompleteWarehousingByForce: () => void;
}) {
  const history = useHistory();

  const { currentUser } = useAppSelector((state) => {
    return {
      currentUser: authSelectors.getCurrentUser(),
    };
  });

  const currentUserId = currentUser?.id;
  const currentUserIsMainManager = mainManagerId === currentUserId;

  const {
    CountForScanningModal,
    handleDirectInputModalOpen,
    setCountForScanning,
  } = useCountForScanning();

  const locationListOfWarehouse = useRecoilValue(
    FULFILLMENT_COMMON_ATOMS.LOCATION_LIST_OF_WAREHOUSE
  );

  const {
    fetchImageInfoBySKUId,
    SKUImageInfoModal,
    ResponseHandlerOfGettingSKUInfo,
    ResponseHandlerOfGettingFileURLList,
  } = useSKUImageInfoModal();

  const getWorkerById = useGetWorkerByIdFactory();

  const { removePersonInCharge, ResponseHandlerOfRemovingPersonInCharge } =
    useRemoveWarehousingPersonInCharge();

  const {
    resetWarehousingOfItem,
    ResponseHandlerOfResetWarehousingOfItemExceptPlacer,
  } = useResetWarehousingOfItem();

  const {
    completeWarehousingByForce,
    ResponseHandlerOfCompletingWarehousingByForce,
  } = useCompleteWarehousingByForce();

  const handleCountAddByDirectInput = useCallback(
    (counterKey: string) => (count: number | undefined) => {
      if (!count) return;

      const target = skuCounting.counter.counterInfo[counterKey];

      skuCounting.counter.addCountByInput({ id: counterKey, count });

      setRowInfoToHighlight({ rowKey: target.itemId });

      changeScanModeToLocationOnFullCount({
        setScanMode,
        count,
        max: target.max,
      });
    },
    [setRowInfoToHighlight, setScanMode, skuCounting.counter]
  );

  const resetLocalCount = useCallback(
    ({
        hasMultiLocation,
        placeItems,
        skuId,
        itemId,
        isGroupedItem,
      }: {
        hasMultiLocation: boolean;
        placeItems: ReceivingPlaceItem[];
        skuId: number;
        itemId: number;
        isGroupedItem: boolean;
      }) =>
      () => {
        if (hasMultiLocation) {
          const counterKeyListToReset = placeItems.map((placeItem) =>
            getCounterKeyForMultiLocationWarehousing(skuId, placeItem.placingId)
          );

          counterKeyListToReset.forEach((counterKey) =>
            resetCountByCounterKey(counterKey)()
          );
          return;
        }

        const counterKey = getCounterKeyByStatus({
          isGroupedItem,
          skuId,
          itemId,
        });
        resetCountByCounterKey(counterKey)();
      },
    [resetCountByCounterKey]
  );

  /**
   * rowType에 맞는 row를 생성한다.
   */
  const createRow = useCallback(
    ({
      rowType,
      v,
      hasMultiLocation,
      currentCount,
      goalCount,
    }: {
      rowType: "basicRow" | "mainRow" | "subRow";
      v: WarehousingItem;
      hasMultiLocation: boolean;
      currentCount: number;
      goalCount: number;
    }) => {
      // 불일치 상품
      const isUnverifiedSku = checkForUnverifiedItem(v);

      const isGroupedItem = rowType === "subRow";

      const counterKey = getCounterKeyByStatus({
        isGroupedItem: rowType === "subRow",
        skuId: v.sku.id,
        itemId: v.id,
      });

      const skuId = getFormattedSingleSkuId(v.sku.id);

      const tempLocationName = getLocationName({
        skuId: v.sku.id,
        itemId: v.id,
        counterData: skuCounting.counter.counterInfo,
        placeItems: v.placeItems,
        selectedLocation,
        hasMultiLocation,
        locationListOfWarehouse,
        placingIdInProgress: skuCounting.skuInProgress?.placingId,
        isGroupedItem,
      });

      const currentUserIsSingleLocationWarehousingAssignee =
        !hasMultiLocation && v.placeItems?.[0].placerId === currentUserId;
      const completedSingleLocationWarehousing =
        !hasMultiLocation && v.placeItems?.[0].isCompletePlacing;
      const skuInProgressInSingleLocationInspection =
        !hasMultiLocation &&
        v.placeItems[0]?.placingId === skuCounting.skuInProgress?.placingId;
      const canOpenDirectInputModal =
        currentUserIsSingleLocationWarehousingAssignee &&
        !completedSingleLocationWarehousing &&
        skuInProgressInSingleLocationInspection;

      const hasRightToReset = getHasRightToReset({
        hasMultiLocation,
        currentUserId,
        currentUserIsMainManager,
        placeItems: v.placeItems,
      });
      const canReset = getCanReset({
        skuId: v.sku.id,
        itemId: v.id,
        counterData: skuCounting.counter.counterInfo,
        hasRightToReset,
        hasMultiLocation,
        isGroupedItem,
      });

      const completedWarehousing = v.placeItems.every(
        (pi) => pi.isCompletePlacing
      );
      const canCompleteWarehousingByForce =
        !completedWarehousing && currentUserIsMainManager && !currentCount;

      const confirmedAsSingleLocationWarehousing =
        checkConfirmedAsSingleLocationWarehousing(v);
      const canSelectMultiLocation = !confirmedAsSingleLocationWarehousing;

      const isSelectedGroupedItem = v.id === groupedItemIdInProgress;
      const isDisabledGroupedItem = completedWarehousing || hasMultiLocation;

      const itemStatusLabel = getWarehousingItemStatusLabel(v.processStatus);

      const managementDateLabel = getManagementDateLabel(
        v.sku.managementKind,
        v.managementDate
      );

      const rowKey = rowType === "mainRow" ? `main-row-${v.skuId}` : v.id;

      const selection = (() => {
        if (!isGroupedItem) {
          return "";
        }

        if (isDisabledGroupedItem) {
          return (
            <Icon
              type="checkStrokeCircleDisabled"
              size={1}
              color={COLOR.grayScale_300}
            />
          );
        }

        if (isSelectedGroupedItem) {
          return (
            <Icon
              type="radioSelected"
              size={1}
              color={COLOR.primaryBlue}
              onClick={() => setGroupedItemIdInProgress(0)}
            />
          );
        }

        return (
          <Icon
            type="radio"
            size={1}
            color={COLOR.grayScale_700}
            onClick={() => setGroupedItemIdInProgress(v.id)}
          />
        );
      })();

      const location = (() => {
        if (rowType === "mainRow") {
          return "";
        }

        if (isGroupedItem) {
          return <SubRowIndicator>{tempLocationName}</SubRowIndicator>;
        }

        return tempLocationName;
      })();

      const SKUId = (() => {
        if (rowType === "mainRow") {
          return (
            <SKUInfo
              skuId={v.sku.id}
              skuBarcode={v.sku.barCode}
              handleSKUIdClick={fetchImageInfoBySKUId}
            />
          );
        }

        if (isUnverifiedSku) {
          return (
            <UnverifiedSku>
              <Styled.skuIdContainer>
                <SKUInfo
                  skuId={v.sku.id}
                  skuBarcode={v.sku.barCode}
                  handleSKUIdClick={fetchImageInfoBySKUId}
                />

                <ItemStatus
                  statusLabel={itemStatusLabel}
                  isUnverifiedSku={isUnverifiedSku}
                  isTable
                />
              </Styled.skuIdContainer>
            </UnverifiedSku>
          );
        }

        return (
          <Styled.skuIdContainer>
            <SKUInfo
              skuId={v.sku.id}
              skuBarcode={v.sku.barCode}
              handleSKUIdClick={fetchImageInfoBySKUId}
            />

            <ItemStatus
              statusLabel={itemStatusLabel}
              isUnverifiedSku={isUnverifiedSku}
              isTable
            />
          </Styled.skuIdContainer>
        );
      })();

      const count = (() => {
        if (rowType === "mainRow") {
          return getStyledSKUCount({ currentCount, goalCount });
        }

        return (
          <CountForScanning
            canOpenDirectInputModal={canOpenDirectInputModal}
            currentQty={currentCount}
            quantity={goalCount ?? 0}
            openDirectInputModal={() => {
              handleDirectInputModalOpen();
              setCountForScanning({
                type: "returningWarehousing",
                skuId: v.sku.id,
                quantity: goalCount ?? 0,
                onCountAdd: handleCountAddByDirectInput(counterKey),
                statusLabel: itemStatusLabel,
                isUnverifiedSku,
                managementKind: v.sku.managementKind,
                managementDate: v.managementDate,
              });
            }}
          />
        );
      })();

      const managementDate = (() => {
        const usesManagementDate = checkUsesManagementDate({
          managementKind: v.sku.managementKind,
          managementDate: v.managementDate,
        });

        if (!usesManagementDate) return "-";

        if (rowType === "basicRow") {
          return (
            <Styled.basicRowOfManagementDateContainer>
              {v.sku.managementKind && (
                <span>
                  {INVENTORY_MANAGEMENT_KIND_MAP[v.sku.managementKind]}
                </span>
              )}
              <span>{toFormattedDate(v.managementDate, "YYYY-MM-DD")}</span>
            </Styled.basicRowOfManagementDateContainer>
          );
        }

        if (rowType === "mainRow") {
          return v.sku.managementKind
            ? INVENTORY_MANAGEMENT_KIND_MAP[v.sku.managementKind]
            : "";
        }

        if (rowType === "subRow") {
          return toFormattedDate(v.managementDate, "YYYY-MM-DD");
        }

        return "";
      })();

      const personInCharge = (() => {
        if (rowType === "mainRow") {
          return "";
        }

        return (
          <PersonInCharge
            worker={getPersonInCharge({
              placeItems: v.placeItems,
              getWorkerById,
            })}
            isRemovable={currentUserIsMainManager}
            openConfirmModal={() => {
              setConfirmModal({
                uiType: "titleOnly",
                title: "SKU 담당자를 삭제하시겠습니까?",
                actions: {
                  actionPositive: {
                    label: "예",
                    handleClick: removePersonInCharge({
                      returningId,
                      itemId: v.id,
                      resetAfterRemoveInProgressPersonInCharge:
                        resetCountByCounterKey(counterKey),
                    }),
                  },
                  actionNegative: {
                    label: "아니오",
                    handleClick: () => setConfirmModal(undefined),
                  },
                },
              });
            }}
          />
        );
      })();

      const completeStatus = (() => {
        if (rowType === "mainRow") {
          return "";
        }

        if (v.placeItems.some((p) => p.isCompletePlacing)) {
          return "Y";
        }

        return "N";
      })();

      const reset = (() => {
        if (rowType === "mainRow") {
          return "";
        }

        return (
          <ResetCounting
            canReset={canReset}
            openConfirmModal={() => {
              setConfirmModal({
                uiType: "content",
                title: (
                  <>
                    {skuId}(SKU ID)
                    <br />
                    상태:{" "}
                    <ItemStatus
                      statusLabel={itemStatusLabel}
                      isUnverifiedSku={isUnverifiedSku}
                    />
                    {managementDateLabel && (
                      <>
                        <br />
                        {managementDateLabel}
                      </>
                    )}
                  </>
                ),
                body: (
                  <>
                    초기화를 진행하겠습니까? <br />
                    (분할내역도 초기화 됩니다.)
                  </>
                ),
                actions: {
                  actionPositive: {
                    label: "예",
                    handleClick: resetWarehousingOfItem({
                      returningId,
                      itemId: v.id,
                      resetLocalCount: resetLocalCount({
                        hasMultiLocation,
                        placeItems: v.placeItems,
                        skuId: v.sku.id,
                        itemId: v.id,
                        isGroupedItem,
                      }),
                    }),
                  },
                  actionNegative: {
                    label: "아니오",
                    handleClick: () => setConfirmModal(undefined),
                  },
                },
              });
            }}
          />
        );
      })();

      const completeByForce = (() => {
        if (rowType === "mainRow") {
          return "";
        }

        return (
          <CompleteByForce
            canCompleteByForce={canCompleteWarehousingByForce}
            openConfirmModal={() => {
              setConfirmModal({
                uiType: "titleOnly",
                title: (
                  <>
                    강제로 완료처리하시겠습니까? (SKU ID: {skuId} / 상태:{" "}
                    <ItemStatus
                      statusLabel={itemStatusLabel}
                      isUnverifiedSku={isUnverifiedSku}
                    />
                    {managementDateLabel && ` / ${managementDateLabel}`})
                  </>
                ),
                actions: {
                  actionPositive: {
                    label: "예",
                    handleClick: completeWarehousingByForce({
                      returningId,
                      itemId: v.id,
                      resetAfterCompleteWarehousingByForce: () => {
                        resetAfterCompleteWarehousingByForce();
                        setConfirmModal(undefined);
                      },
                    }),
                  },
                  actionNegative: {
                    label: "아니오",
                    handleClick: () => setConfirmModal(undefined),
                  },
                },
              });
            }}
          />
        );
      })();

      const selectMultiLocation = (() => {
        if (rowType === "mainRow") {
          return "";
        }

        return (
          <SelectMultiLocation
            type="warehousing"
            canSelectMultiLocation={canSelectMultiLocation}
            openConfirmModal={() => {
              setConfirmModal({
                uiType: "content",
                title: (
                  <>
                    {skuId}(SKU ID)
                    <br />
                    상태:{" "}
                    <ItemStatus
                      statusLabel={itemStatusLabel}
                      isUnverifiedSku={isUnverifiedSku}
                    />
                    {managementDateLabel && (
                      <>
                        <br />
                        {managementDateLabel}
                      </>
                    )}
                  </>
                ),
                body: `분할입고를 진행하겠습니까?`,
                actions: {
                  actionPositive: {
                    label: "예",
                    handleClick: () => {
                      setConfirmModal(undefined);
                      history.push({
                        pathname: `${history.location?.pathname}/multi-location/${v.id}`,
                      });
                    },
                  },
                  actionNegative: {
                    label: "아니오",
                    handleClick: () => setConfirmModal(undefined),
                  },
                },
              });
            }}
          />
        );
      })();

      const mapped: TableDataListItem<ReturningWarehousingDetailTableItem> = {
        rowKey,
        selection,
        location,
        SKUId,
        count,
        managementDate,
        personInCharge,
        completeStatus,
        reset,
        completeByForce,
        selectMultiLocation,
      };

      return mapped;
    },
    [
      completeWarehousingByForce,
      currentUserId,
      currentUserIsMainManager,
      fetchImageInfoBySKUId,
      getWorkerById,
      groupedItemIdInProgress,
      handleCountAddByDirectInput,
      handleDirectInputModalOpen,
      history,
      locationListOfWarehouse,
      removePersonInCharge,
      resetAfterCompleteWarehousingByForce,
      resetCountByCounterKey,
      resetLocalCount,
      resetWarehousingOfItem,
      returningId,
      selectedLocation,
      setConfirmModal,
      setCountForScanning,
      setGroupedItemIdInProgress,
      skuCounting.counter.counterInfo,
      skuCounting.skuInProgress?.placingId,
    ]
  );

  const tableDataList = useMemo(() => {
    const mapBySKU = convertItemListToMapBySKU(detailItems);

    if (!mapBySKU) return [];

    return Object.values(mapBySKU).reduce<
      TableDataListItem<ReturningWarehousingDetailTableItem>[]
    >((a, itemList) => {
      if (!itemList || !itemList.length) return a;

      /**
       * sku를 구성하는 item이 2개 이상인 경우 subRow로 표시한다.
       **/
      const usesSubRow = itemList.length > 1;

      if (!usesSubRow) {
        return [
          ...a,
          ...itemList.map((item) => {
            const hasMultiLocation = getHasMultiLocationWarehousing(
              item.actualQty,
              item.placeItems
            );

            return createRow({
              rowType: "basicRow",
              v: item,
              hasMultiLocation,
              currentCount: getCurrentSKUCount({
                skuId: item.sku.id,
                itemId: item.id,
                counterData: skuCounting.counter.counterInfo,
                placeItems: item.placeItems,
                hasMultiLocation,
                isGroupedItem: false,
              }),
              goalCount: item.actualQty,
            });
          }),
        ];
      }

      // group으로 묶인경우 mainRow 하위의 subRow로 구분하여 표시한다
      const mainRow: TableDataListItem<ReturningWarehousingDetailTableItem> =
        (() => {
          /**
           * mainRow는 item별 공통 정보를 만들므로 임의의 요소(첫번째요소)를 사용해서 생성할 수 있다.
           */
          const baseItem = itemList[0];

          return createRow({
            rowType: "mainRow",
            v: baseItem,
            hasMultiLocation: false,
            currentCount: itemList.reduce((a, c) => {
              // mainRow의 currentCount는 subRow들의 currentCount 합산
              return (
                a +
                getCurrentSKUCount({
                  skuId: c.sku.id,
                  itemId: c.id,
                  counterData: skuCounting.counter.counterInfo,
                  placeItems: c.placeItems,
                  hasMultiLocation: getHasMultiLocationWarehousing(
                    c.actualQty,
                    c.placeItems
                  ),
                  isGroupedItem: true,
                })
              );
            }, 0),
            goalCount: itemList.reduce((a, c) => {
              // mainRow의 goalCount는 subRow들의 actualQty를 합산
              return a + (c.actualQty ?? 0);
            }, 0),
          });
        })();

      mainRow.subRowInfo = (() => {
        return {
          subRowList: itemList.map((v) => {
            const hasMultiLocation = getHasMultiLocationWarehousing(
              v.actualQty,
              v.placeItems
            );

            return createRow({
              rowType: "subRow",
              v,
              hasMultiLocation,
              currentCount: getCurrentSKUCount({
                skuId: v.sku.id,
                itemId: v.id,
                counterData: skuCounting.counter.counterInfo,
                placeItems: v.placeItems,
                hasMultiLocation,
                isGroupedItem: true,
              }),
              goalCount: v.actualQty,
            });
          }),

          initialAccordionStatus: "opened",
        };
      })();

      return [...a, mainRow];
    }, []);
  }, [detailItems, createRow, skuCounting.counter.counterInfo]);

  return (
    <>
      <TableForCounting<ReturningWarehousingDetailTableItem>
        isWindowed
        height={300}
        rowInfoToHighlight={rowInfoToHighlight}
        columnInfo={{
          selection: {
            label: "선택",
            fixedWidth: 32,
          },
          location: {
            label: "위치",
            fixedWidth: 110,
          },
          SKUId: {
            label: (
              <>
                SKU ID / 상품바코드 <br /> 상태
              </>
            ),
            fixedWidth: 120,
          },
          count: {
            label: "카운트",
            fixedWidth: 100,
          },
          managementDate: {
            label: "관리일자",
            fixedWidth: 78,
          },
          personInCharge: {
            label: "담당자",
            fixedWidth: 110,
          },
          completeStatus: {
            label: (
              <>
                입고완료
                <br />
                여부
              </>
            ),
            fixedWidth: 58,
          },
          reset: {
            label: "초기화",
            fixedWidth: 74,
          },
          completeByForce: {
            label: (
              <>
                강제처리
                <br />
                (카운트: 0)
              </>
            ),
            fixedWidth: 74,
          },
          selectMultiLocation: {
            label: "분할입고",
            fixedWidth: 74,
          },
        }}
        dataList={tableDataList}
      />

      {CountForScanningModal}

      {SKUImageInfoModal}
      {ResponseHandlerOfGettingSKUInfo}
      {ResponseHandlerOfGettingFileURLList}

      {ResponseHandlerOfRemovingPersonInCharge}
      {ResponseHandlerOfResetWarehousingOfItemExceptPlacer}
      {ResponseHandlerOfCompletingWarehousingByForce}
    </>
  );
}

export default memo(SKUList);
