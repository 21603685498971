import { useCallback } from "react";

import { TopicsConfig } from "./config";

type SecondArgToOptional<T, U> = U extends undefined ? [T] : [T, U];

/**
 * pubsub 패턴을 사용하기 위한 훅
 * config.ts 에 사용할 토픽을 정의해주어야 함
 */
export function usePubSub() {
  const publish = useCallback(
    <Topic extends keyof TopicsConfig, Data extends TopicsConfig[Topic]>(
      ...args: SecondArgToOptional<Topic, Data>
    ) => {
      const [topic, data] = args;

      const nativeEvent = new CustomEvent(topic, {
        detail: data,
      });

      document.dispatchEvent(nativeEvent);
    },
    []
  );

  const subscribe = useCallback(
    <Topic extends keyof TopicsConfig, Data extends TopicsConfig[Topic]>(
      topic: Topic,
      listener: (data: Data) => void
    ) => {
      const eventListener = ((event: CustomEvent) => {
        listener(event.detail);
      }) as EventListener;

      document.addEventListener(topic, eventListener, { passive: true });

      return () => {
        document.removeEventListener(topic, eventListener);
      };
    },
    []
  );

  return { publish, subscribe };
}
