import { memo } from "react";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";

function SelectMultiLocation({
  type,
  canSelectMultiLocation,
  openConfirmModal,
}: {
  type: "inspection" | "warehousing";
  canSelectMultiLocation: boolean;
  openConfirmModal: () => void;
}) {
  return (
    <Button
      label={`분할${type === "inspection" ? "검수" : "입고"}`}
      size="small"
      theme="tertiary"
      handleClick={(e) => {
        e.stopPropagation();
        openConfirmModal();
      }}
      disabled={!canSelectMultiLocation}
    />
  );
}

export default memo(SelectMultiLocation);
