import { memo } from "react";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";

function CompleteByForce({
  canCompleteByForce,
  openConfirmModal,
}: {
  canCompleteByForce: boolean;
  openConfirmModal: () => void;
}) {
  return (
    <Button
      label="강제처리"
      size="small"
      theme="tertiary"
      handleClick={(e) => {
        e.stopPropagation();
        openConfirmModal();
      }}
      disabled={!canCompleteByForce}
    />
  );
}

export default memo(CompleteByForce);
