import { ReactNode, useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { SET_INSPECTION_DONE_REQ_ITEM } from "@sellernote/_shared/src/api-interfaces/boful-api/receiving";
import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import Icon from "@sellernote/_shared/src/componentsToMoveToV1/Icon";
import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import { INVENTORY_MANAGEMENT_KIND_MAP } from "@sellernote/_shared/src/constants/fulfillment/inventory";
import { TableDataListItem } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import RECEIVING_QUERY, {
  RECEIVING_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/fulfillment/RECEIVING_QUERY";
import { FULFILLMENT_RECEIVING_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/receiving";
import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { ManagementKind } from "@sellernote/_shared/src/types/fulfillment/inventory";
import { ReceivingItem } from "@sellernote/_shared/src/types/fulfillment/receiving";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import { getObjectKeyList } from "@sellernote/_shared/src/utils/common/object";
import { replaceEmptyToDash } from "@sellernote/_shared/src/utils/common/string";
import { validateCount } from "@sellernote/_shared/src/utils/common/validation";
import {
  checkUsesManagementDate,
  convertItemListToMapBySKU,
} from "@sellernote/_shared/src/utils/fulfillment/common";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";
import {
  checkForNormalItemAsInspection,
  checkForUnverifiedItemAsInspection,
} from "@sellernote/_shared/src/utils/fulfillment/inspection";
import InputText from "@sellernote/_sds-v1/src/components/input/InputText";
import Table from "@sellernote/_sds-v1/src/components/table/Table";

import Styled from "./index.styles";

interface MixQtyTableItem {
  skuId: ReactNode;
  managementDate: string;
  actualQty: number;
  mixQty: ReactNode;
}

interface MixQtyList {
  skuId: number;
  itemId: number;
  skuBarcode: string | undefined;
  actualQty: number;
  mixQty: number;
  validationMessage: React.ReactNode;
  managementDate?: string;
  managementKind?: ManagementKind;
}

type UnverifiedMixQtyTableItem = {
  product: ReactNode;
  actualQty: number;
  mixQty: ReactNode;
};

type UnverifiedMixQtyList = {
  itemId: number;
  skuBarcode: string | null;
  actualQty: number;
  mixQty: number;
  validationMessage: React.ReactNode;
};

const initMixQtyList = (skuItems: ReceivingItem[]): MixQtyList[] =>
  skuItems.filter(checkForNormalItemAsInspection).map((item) => ({
    skuId: item.skuId,
    itemId: item.id,
    skuBarcode: item.sku.barCode,
    actualQty: item.inspectItems.reduce(
      (total, inspectItem) => total + (inspectItem.actualQty ?? 0),
      0
    ),
    mixQty: 0,
    validationMessage: undefined,
    managementDate: item.managementDate,
    managementKind: item.sku.managementKind,
  }));

const initUnverifiedMixQtyList = (
  skuItems: ReceivingItem[]
): UnverifiedMixQtyList[] =>
  skuItems.filter(checkForUnverifiedItemAsInspection).map((item) => ({
    itemId: item.id,
    skuBarcode: item.skuBarCode,
    actualQty: item.actualQty ?? 0,
    mixQty: 0,
    validationMessage: undefined,
  }));

export default function useInputMixQty({
  receivingId,
}: {
  receivingId: number;
}) {
  const queryClient = useQueryClient();

  const history = useHistory();

  const skuItems = useRecoilValue(FULFILLMENT_RECEIVING_SELECTORS.SKU_ITEMS);

  const [isVisibleCheckHasMixQtyModal, setIsVisibleCheckHasMixQtyModal] =
    useState(false);
  const [isVisibleInputMixQtyModal, setIsVisibleInputMixQtyModal] =
    useState(false);
  // 검수 상품
  const [mixQtyList, setMixQtyList] = useState<MixQtyList[]>(() =>
    initMixQtyList(skuItems)
  );
  // 불일치상품
  const [unverifiedMixQtyList, setUnverifiedMixQtyList] = useState<
    UnverifiedMixQtyList[]
  >(() => initUnverifiedMixQtyList(skuItems));

  const {
    mutate: setInspectionDone,
    ResponseHandler: ResponseHandlerOfSetInspectionDone,
  } = RECEIVING_QUERY.useSetInspectionDone({
    receivingId,
    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();
        history.replace("/receiving/inspection");

        // 검수 마감 이후 입고화면 진입 시 초기 로직이 캐싱된 데이터로 동작하기 때문에 캐싱된 데이터를 제거한다.
        queryClient.removeQueries(
          RECEIVING_QUERY_KEY_GEN.getManagerReceivingDetail({ receivingId })
        );
      },
      customizeMessage: () => ({
        messageType: "titleOnly",
        title: "검수 마감 처리되었습니다.",
      }),
    },
  });

  const handleCheckHasMixQtyModalOpen = useCallback(() => {
    setIsVisibleCheckHasMixQtyModal(true);
  }, []);

  const handleCheckHasMixQtyModalClose = useCallback(() => {
    setIsVisibleCheckHasMixQtyModal(false);
  }, []);

  const handleInputMixQtyModalOpen = useCallback(() => {
    handleCheckHasMixQtyModalClose();
    setIsVisibleInputMixQtyModal(true);
  }, [handleCheckHasMixQtyModalClose]);

  const handleInputMixQtyModalClose = useCallback(() => {
    setIsVisibleInputMixQtyModal(false);
    setMixQtyList(initMixQtyList(skuItems));
    setUnverifiedMixQtyList(initUnverifiedMixQtyList(skuItems));
  }, [skuItems]);

  const handleInspectionDoneSet = useCallback(() => {
    const inspectedItems = skuItems.reduce(
      (inspectedItems: SET_INSPECTION_DONE_REQ_ITEM[], item) => {
        item.inspectItems.forEach((inspectItem) => {
          const normalMixQty = mixQtyList.find(
            (mixQtyItem) => mixQtyItem.itemId === item.id
          )?.mixQty;
          const unverifiedMixQty = unverifiedMixQtyList.find(
            (mixQtyItem) => mixQtyItem.itemId === item.id
          )?.mixQty;
          const hasNormalMixQty = Boolean(normalMixQty);
          const hasUnverifiedMixQty = Boolean(unverifiedMixQty);
          const hasMixQty = hasNormalMixQty || hasUnverifiedMixQty;

          const isAddedNormalItem = inspectedItems.some(
            (inspectedItem) => inspectedItem.itemId === item.id
          );
          const isAddedUnverifiedItem = inspectedItems.some(
            (inspectedItem) => inspectedItem.itemId === item.id
          );
          const isAddedItem = isAddedNormalItem || isAddedUnverifiedItem;

          // 불일치 상품은 skuId가 없기 때문에 itemId가 그 역할을 대신함
          const isUnverifiedItem = checkForUnverifiedItemAsInspection(item);

          if (hasMixQty && !isAddedItem) {
            inspectedItems.push({
              skuId: item.skuId,
              itemId: item.id,
              // 검수마감은 모든 검수가 완료된 상태이기 때문에 actualQty가 항상 존재한다.
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              actualQty: inspectItem.actualQty!,
              inspectingId: inspectItem.inspectingId,
              ...(isUnverifiedItem
                ? { mixQty: unverifiedMixQty }
                : { mixQty: normalMixQty }),
            });
            return;
          }

          inspectedItems.push({
            skuId: item.skuId,
            itemId: item.id,
            // 검수마감은 모든 검수가 완료된 상태이기 때문에 actualQty가 항상 존재한다.
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            actualQty: inspectItem.actualQty!,
            inspectingId: inspectItem.inspectingId,
          });
        });

        return inspectedItems;
      },
      []
    );

    setInspectionDone({ inspectedItems });
  }, [skuItems, setInspectionDone, unverifiedMixQtyList, mixQtyList]);

  const setMixQtyValue = useCallback(
    (type: "normal" | "unverified", inputId: number) =>
      (inputValue: number) => {
        type === "normal"
          ? setMixQtyList((prevMixQtyList) =>
              prevMixQtyList.map((mixQtyItem) =>
                mixQtyItem.itemId === inputId
                  ? {
                      ...mixQtyItem,
                      mixQty: Number.isNaN(inputValue) ? 0 : inputValue,
                      validationMessage: validateCount({
                        count: inputValue,
                        maxCount: mixQtyItem.actualQty,
                        message: { maxCount: "검수 수량 이내로만 입력 가능" },
                      }).message,
                    }
                  : mixQtyItem
              )
            )
          : setUnverifiedMixQtyList((prevMixQtyList) =>
              prevMixQtyList.map((mixQtyItem) =>
                mixQtyItem.itemId === inputId
                  ? {
                      ...mixQtyItem,
                      mixQty: Number.isNaN(inputValue) ? 0 : inputValue,
                      validationMessage: validateCount({
                        count: inputValue,
                        maxCount: mixQtyItem.actualQty,
                        message: { maxCount: "검수 수량 이내로만 입력 가능" },
                      }).message,
                    }
                  : mixQtyItem
              )
            );
      },
    []
  );

  const createRow = useCallback(
    ({
      rowType,
      item,
    }: {
      rowType: "basicRow" | "mainRow" | "subRow";
      item: MixQtyList;
    }): TableDataListItem<MixQtyTableItem> => {
      const rowKey =
        rowType === "mainRow" ? `main-row-${item.skuId}` : item.itemId;

      const skuId = (
        <Styled.skuId>
          {getFormattedSingleSkuId(item.skuId)}

          {!!item.skuBarcode && (
            <>
              <br />({item.skuBarcode})
            </>
          )}
        </Styled.skuId>
      );

      const managementDate = (() => {
        if (rowType === "basicRow") return "-";

        if (rowType === "mainRow") {
          return item.managementKind
            ? INVENTORY_MANAGEMENT_KIND_MAP[item.managementKind]
            : "";
        }

        if (rowType === "subRow") {
          return toFormattedDate(item.managementDate, "YYYY-MM-DD");
        }

        return "";
      })();

      const actualQty = item.actualQty;

      const mixQty = (() => {
        if (rowType === "mainRow") return "";

        return (
          <InputText
            borderType="outline"
            valueType="int"
            width={7.5}
            value={item.mixQty}
            setValue={setMixQtyValue("normal", item.itemId)}
            placeholder="0"
            errorMessage={item.validationMessage}
          />
        );
      })();

      return {
        rowKey,
        skuId,
        managementDate,
        actualQty,
        mixQty,
      };
    },
    [setMixQtyValue]
  );

  const dataList: TableDataListItem<MixQtyTableItem>[] = useMemo(() => {
    const mapBySKU = convertItemListToMapBySKU(mixQtyList);

    if (!mapBySKU) return [];

    const result = getObjectKeyList(mapBySKU).reduce<
      TableDataListItem<MixQtyTableItem>[]
    >((a, SKUId) => {
      const itemList = mapBySKU[SKUId];

      if (!itemList || !itemList?.length) return a;

      /**
       * 관리일자를 사용하는지 여부
       * - SKU별로 관리일자 타입이 같으므로 첫 번째 요소의 값으로만으로 판단할 수 있다.
       **/
      const usesManagementDate = checkUsesManagementDate({
        managementKind: itemList[0].managementKind,
        managementDate: itemList[0].managementDate,
      });

      if (!usesManagementDate) {
        /**
         * 관리일자를 사용하지 않는다면 일반적인 Row로 표시한다.
         */
        return [
          ...a,
          ...itemList.map((item) => {
            return createRow({
              rowType: "basicRow",
              item,
            });
          }),
        ];
      } else {
        // 관리일자를 사용한다면 mainRow 하위의 subRow로 구분하여 표시한다

        const mainRow: TableDataListItem<MixQtyTableItem> = (() => {
          /**
           * mainRow는 item별 공통 정보를 만들므로 임의의 요소(첫번째요소)를 사용해서 생성할 수 있다.
           */
          const target = itemList[0];

          // mainRow의 actualQty는 subRow들의 총계를 반환
          const actualQty = itemList.reduce((a, c) => a + c.actualQty, 0);

          return {
            ...createRow({
              rowType: "mainRow",
              item: { ...target, actualQty },
            }),
          };
        })();

        mainRow.subRowInfo = (() => {
          return {
            subRowList: itemList.map((v) => {
              return createRow({
                rowType: "subRow",
                item: v,
              });
            }),
          };
        })();

        return [...a, mainRow];
      }
    }, []);

    return result;
  }, [createRow, mixQtyList]);

  const unverifiedDataList: TableDataListItem<UnverifiedMixQtyTableItem>[] =
    useMemo(
      () =>
        unverifiedMixQtyList.map((item, index) => {
          const { itemId, skuBarcode, actualQty, mixQty, validationMessage } =
            item;

          return {
            rowKey: itemId,

            product: (
              <Styled.skuId>
                불일치 {index + 1} /
                <br />({replaceEmptyToDash(skuBarcode)})
              </Styled.skuId>
            ),

            actualQty,

            mixQty: (
              <InputText
                borderType="outline"
                valueType="int"
                width={7.5}
                value={mixQty}
                setValue={setMixQtyValue("unverified", itemId)}
                placeholder="0"
                errorMessage={validationMessage}
              />
            ),
          };
        }),
      [setMixQtyValue, unverifiedMixQtyList]
    );

  const isValidatedModal = useMemo(() => {
    const hasNormalMixQty = mixQtyList.some(
      (mixQtyItem) => mixQtyItem.mixQty > 0
    );
    const hasUnverifiedMixQty = unverifiedMixQtyList.some(
      (mixQtyItem) => mixQtyItem.mixQty > 0
    );
    const hasMixQty = hasNormalMixQty || hasUnverifiedMixQty;

    const hasNormalValidationMessage = mixQtyList.some(
      (mixQtyItem) => mixQtyItem.validationMessage
    );
    const hasUnverifiedValidationMessage = unverifiedMixQtyList.some(
      (mixQtyItem) => mixQtyItem.validationMessage
    );
    const hasValidationMessage =
      hasNormalValidationMessage || hasUnverifiedValidationMessage;

    return hasMixQty && !hasValidationMessage;
  }, [mixQtyList, unverifiedMixQtyList]);

  const CheckHasMixQtyModal = useMemo(
    () => (
      <Modal
        active={isVisibleCheckHasMixQtyModal}
        title="혼적 상품이 있습니까?"
        uiType="titleOnly"
        actionPositive={{
          label: "예",
          handleClick: handleInputMixQtyModalOpen,
        }}
        actionNegative={{
          label: "아니오",
          handleClick: handleInspectionDoneSet,
        }}
      />
    ),
    [
      handleInputMixQtyModalOpen,
      handleInspectionDoneSet,
      isVisibleCheckHasMixQtyModal,
    ]
  );

  const hasUnverifiedList = !!unverifiedMixQtyList.length;

  const InputMixQtyModal = useMemo(
    () => (
      <Modal
        active={isVisibleInputMixQtyModal}
        uiType="contentWithCustomBody"
        title="혼적 수량을 입력하세요."
        body={
          <Styled.inputMixQtyModalBody>
            {hasUnverifiedList && (
              <>
                <div className="unverified-label">
                  <Icon type="warning" size={1} color={COLOR.pointWarning} />
                  <span>불일치 상품</span>
                </div>

                <Table<UnverifiedMixQtyTableItem>
                  columnInfo={{
                    product: {
                      label: "상품/상품 바코드",
                      fixedWidth: 120,
                    },
                    actualQty: {
                      label: "검수수량",
                      fixedWidth: 100,
                    },
                    mixQty: {
                      label: "혼적수량",
                      fixedWidth: 160,
                    },
                  }}
                  dataList={unverifiedDataList}
                />
              </>
            )}

            <Table<MixQtyTableItem>
              columnInfo={{
                skuId: {
                  label: (
                    <>
                      SKU ID
                      <br />
                      (상품 바코드)
                    </>
                  ),
                  fixedWidth: 120,
                },
                managementDate: {
                  label: "관리일자",
                  fixedWidth: 100,
                },
                actualQty: {
                  label: "검수수량",
                  fixedWidth: 100,
                },
                mixQty: {
                  label: "혼적수량",
                  fixedWidth: 160,
                },
              }}
              dataList={dataList}
            />

            <Button
              theme="primary"
              size="normal"
              label="완료"
              handleClick={handleInspectionDoneSet}
              disabled={!isValidatedModal}
            />
          </Styled.inputMixQtyModalBody>
        }
        onClose={handleInputMixQtyModalClose}
      />
    ),
    [
      dataList,
      handleInputMixQtyModalClose,
      handleInspectionDoneSet,
      hasUnverifiedList,
      isValidatedModal,
      isVisibleInputMixQtyModal,
      unverifiedDataList,
    ]
  );

  return {
    handleCheckHasMixQtyModalOpen,

    ResultHandlerOfInputMixQty: (
      <>
        {CheckHasMixQtyModal}
        {InputMixQtyModal}

        {ResponseHandlerOfSetInspectionDone}
      </>
    ),
  };
}
