import { memo } from "react";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";

function ResetCountingAsPartial({
  canReset,
  openConfirmModal,
}: {
  canReset: boolean;
  openConfirmModal: () => void;
}) {
  return (
    <Button
      label="초기화"
      size="small"
      theme="tertiary"
      handleClick={openConfirmModal}
      disabled={!canReset}
    />
  );
}

export default memo(ResetCountingAsPartial);
