import { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";

import { TableRowInfoToHighlight } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import { FULFILLMENT_SHIPPING_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/shipping";
import { ManagementKind } from "@sellernote/_shared/src/types/fulfillment/inventory";
import { ScanType } from "@sellernote/_shared/src/types/fulfillment/scan";
import { PickingSKUInfoForScanning } from "@sellernote/_shared/src/types/fulfillment/shipping";

export interface WorkingLocation {
  id: number;
  barcode: string;
  managementKind: ManagementKind | undefined;
  managementDate: string | undefined;
}

export interface CompletedList {
  /** 완료된 위치 목록 */
  locationIdList: Set<number>;
  /** 현재 작업 중인 위치에서 완료된 itemId 목록(초기 기획은 하나의 위치에 여러 개의 상품이 있을 수 있었음) */
  itemIdListByWorkingLocation: Set<number>;
  /** 완료된 itemId 목록 */
  itemIdList: Set<number>;
}

export default function usePickingInvoice() {
  const [workingLocation, setWorkingLocation] = useState<WorkingLocation>();
  const [selectedScanButtonType, setSelectedScanButtonType] = useState<
    ScanType | undefined
  >("shipping");
  const [rowInfoToHighlight, setRowInfoToHighlight] =
    useState<TableRowInfoToHighlight>();

  const [SKUInfoForScanning, setSKUInfoForScanning] = useState<
    PickingSKUInfoForScanning[]
  >([]);
  const completedList = useRef<CompletedList>({
    locationIdList: new Set(),
    itemIdListByWorkingLocation: new Set(),
    itemIdList: new Set(),
  });

  const isFirstSKUScan = useRef(true);

  const pickingList = useRecoilValue(
    FULFILLMENT_SHIPPING_SELECTORS.PICKING_LIST
  );

  useEffect(() => {
    setSKUInfoForScanning(pickingList);
  }, [pickingList]);

  const handleScanButtonClick = useCallback(
    (type: ScanType | undefined) =>
      selectedScanButtonType === type
        ? setSelectedScanButtonType(undefined)
        : setSelectedScanButtonType(type),
    [selectedScanButtonType]
  );

  const allPickingAreCompleted = SKUInfoForScanning.every(
    (v) => v.currentQty === v.quantity
  );

  const resetPickingState = useCallback(() => {
    setSelectedScanButtonType("shipping");
    setWorkingLocation(undefined);
    setRowInfoToHighlight(undefined);
    setSKUInfoForScanning([]);

    isFirstSKUScan.current = true;

    completedList.current.locationIdList.clear();
    completedList.current.itemIdListByWorkingLocation.clear();
    completedList.current.itemIdList.clear();
  }, []);

  return {
    workingLocation,
    setWorkingLocation,

    rowInfoToHighlight,
    setRowInfoToHighlight,

    completedList,

    isFirstSKUScan,

    selectedScanButtonType,
    handleScanButtonClick,

    SKUInfoForScanning,
    setSKUInfoForScanning,

    allPickingAreCompleted,

    resetPickingState,
  };
}
