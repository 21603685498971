import { memo } from "react";

import { getStyledSKUCount } from "@sellernote/_shared/src/utils/fulfillment/sku";
import TextButton from "@sellernote/_sds-v1/src/components/button/TextButton";

import Styled from "./index.styles";

function CountForScanning({
  canOpenDirectInputModal,
  currentQty,
  quantity,
  openDirectInputModal,
}: {
  canOpenDirectInputModal: boolean;
  currentQty: number | undefined;
  // 반품관리 > 검수 > 관리일자가 지정된 상품은 검수단계에서는 관리일자가 지정되기 전이므로 quantity가 존재하지 않는다.
  quantity?: number;
  openDirectInputModal: () => void;
}) {
  return (
    <>
      <Styled.count onClick={(e) => e.stopPropagation()}>
        {quantity ? (
          getStyledSKUCount({ currentCount: currentQty, goalCount: quantity })
        ) : (
          <div>{currentQty ?? 0}</div>
        )}

        <TextButton
          label="직접 입력"
          theme="withIcon"
          icon="arrowRight"
          size="14px"
          handleClick={openDirectInputModal}
          disabled={!canOpenDirectInputModal}
        />
      </Styled.count>
    </>
  );
}

export default memo(CountForScanning);
