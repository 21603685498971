import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSetRecoilState } from "recoil";

import { FULFILLMENT_COMMON_ATOMS } from "@sellernote/_shared/src/states/fulfillment/common";
import { ManagementKind } from "@sellernote/_shared/src/types/fulfillment/inventory";
import { ReceivingItem } from "@sellernote/_shared/src/types/fulfillment/receiving";
import {
  useCounter,
  UseCounterData,
  UseCounterDataValue,
  UseCounterReturn,
} from "@sellernote/_shared/src/utils/common/hook";
import {
  checkUsesManagementDate,
  getNumberParam,
} from "@sellernote/_shared/src/utils/fulfillment/common";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";
import { checkForNormalItemAsInspection } from "@sellernote/_shared/src/utils/fulfillment/inspection";

export interface InspectionCounterSKU {
  counterKey: string;
  itemId: number;
  skuId: number;
  skuBarcode: string | undefined; // 상품 바코드
  itemName: string;
  inspectorId?: number;
  isCompleteInspection?: boolean;
  managementKind?: ManagementKind;
  managementDate?: string;
}

type CounterForInspection = UseCounterReturn<InspectionCounterSKU>;
export type CounterDataForInspection = UseCounterData<InspectionCounterSKU>;

export interface SKUCountingForInspection {
  skuInProgress?: InspectionCounterSKU;
  setSkuInProgress: (val?: InspectionCounterSKU) => void;
  totalCount: string;
  counter: CounterForInspection;
  reset: (items: ReceivingItem[]) => void;
}

export function getCounterKeyFromScanResult({
  counterData,
  scanResult,
  itemIdInprogress,
}: {
  counterData: SKUCountingForInspection;
  scanResult: string;
  itemIdInprogress: number | undefined;
}) {
  return Object.values(counterData.counter.counterInfo).find((counter) => {
    const isScannedItem =
      getFormattedSingleSkuId(counter.skuId) === scanResult ||
      counter.skuBarcode === scanResult;

    const isInprogressItem = itemIdInprogress === counter.itemId;

    // 라디오 버튼을 선택한 상태에는 선택된 상품만 스캔할 수 있다.
    return itemIdInprogress ? isScannedItem && isInprogressItem : isScannedItem;
  })?.counterKey;
}

/**
 * 반품관리 > 검수용 키를 생성.
 * - key에 itemId을 조합하는 이유는 관리일자를 사용하는 경우도 커버하기 위함.
 */
export function getCounterKeyForInspection({
  skuId,
  itemId,
}: {
  skuId: number;
  itemId: number;
}) {
  return `S${skuId}-${itemId}`;
}

export function getIncompleteCounterInfoListFromScannedSkuBarcode({
  counterData,
  scanResult,
}: {
  counterData: SKUCountingForInspection;
  scanResult: string;
}) {
  return Object.values(counterData.counter.counterInfo).filter(
    (counter) =>
      counter.skuBarcode === scanResult && !counter.isCompleteInspection
  );
}

export function getIncompleteCounterKeyFromScannedSkuBarcode({
  counterData,
  scanResult,
}: {
  counterData: SKUCountingForInspection;
  scanResult: string;
}) {
  return Object.values(counterData.counter.counterInfo).find(
    (counter) =>
      counter.skuBarcode === scanResult && !counter.isCompleteInspection
  )?.counterKey;
}

export function getCounterKeyFromScanResultByItemIdInProgress({
  counterData,
  scanResult,
  itemIdInProgress,
}: {
  counterData: SKUCountingForInspection;
  scanResult: string;
  itemIdInProgress: number | undefined;
}) {
  return Object.values(counterData.counter.counterInfo).find(
    (counter) =>
      counter.skuBarcode === scanResult && counter.itemId === itemIdInProgress
  )?.counterKey;
}

export default function useSKUCountingForInspection({
  items,
  setRowInfoToHighlight,
  unverifiedCount,
  setGroupedItemIdInProgress,
}: {
  items?: ReceivingItem[];
  setRowInfoToHighlight: ({ rowKey }: { rowKey: number }) => void;
  unverifiedCount: number;
  setGroupedItemIdInProgress: (itemId: number) => void;
}): SKUCountingForInspection {
  const setCanNotLeavePage = useSetRecoilState(
    FULFILLMENT_COMMON_ATOMS.CAN_NOT_LEAVE_PAGE
  );

  const { search } = useLocation();

  /**
   * inputting이란, 검수 작업 중(검수수량이 확정되기 전)에 비정상 상품 페이지로 이동한 뒤 검수 페이지로 되돌아왔을 때 작업 중인 정보를 되살리기 위한 데이터이다.
   */
  const inputtingSkuId = getNumberParam(search, "skuId");
  const inputtingItemId = getNumberParam(search, "itemId");
  const inputtingInspectionCount = getNumberParam(search, "inspectionCount");

  const counter = useCounter<InspectionCounterSKU>();

  const [skuInProgress, setSkuInProgress] = useState<InspectionCounterSKU>();

  // inputting 데이터를 리스트 데이터에 적용
  const setScanInprogressState = useCallback(
    (counterInfo?: UseCounterDataValue<InspectionCounterSKU>) => {
      if (!counterInfo) return;

      setSkuInProgress(counterInfo);

      setRowInfoToHighlight({ rowKey: counterInfo.itemId });
      setGroupedItemIdInProgress(counterInfo.itemId);
    },
    [setGroupedItemIdInProgress, setRowInfoToHighlight]
  );

  const initCounter = useCallback(
    ({
      counter,
      receivingItemList,
      needReset,
    }: {
      counter: CounterForInspection;
      receivingItemList?: ReceivingItem[];
      needReset?: boolean; // 이전 local에서 카운팅했던 값을 무시할때 사용
    }) => {
      if (!receivingItemList) return;

      const newInfo: CounterDataForInspection = {};

      // 불일치 상품(skuId 가 없는 상품) 필터링 해서 검수 상품을 보여줌.
      receivingItemList.filter(checkForNormalItemAsInspection).forEach((v) => {
        const usesManagementDate = checkUsesManagementDate({
          managementKind: v.sku.managementKind,
          managementDate: v.managementDate,
        });

        const counterKey = getCounterKeyForInspection({
          skuId: v.skuId,
          itemId: v.id,
        });

        if (!counterKey) return;

        const previousCountInfo =
          !needReset && counter.counterInfo
            ? counter.counterInfo[counterKey]
            : null;

        const isInputtingItem = inputtingItemId === v.id;

        newInfo[counterKey] = {
          counterKey,
          itemId: v.id,
          skuId: v.skuId,
          skuBarcode: v.sku.barCode,
          itemName: v.sku.itemName,
          inspectorId: v.inspectorId,
          isCompleteInspection: v.isCompleteInspection,
          // 검수단계에서는 확정되지 않은 상태이므로 표시하지 않는다.
          max: usesManagementDate ? undefined : v.quantity,
          current: isInputtingItem
            ? inputtingInspectionCount ||
              previousCountInfo?.current ||
              v.actualQty
            : previousCountInfo?.current || v.actualQty,
          managementKind: v.sku.managementKind,
          managementDate: v.managementDate,
        };
      });

      counter.initCounterInfo(newInfo);

      const prevInputtingCounterInfo =
        newInfo[
          getCounterKeyForInspection({
            skuId: inputtingSkuId,
            itemId: inputtingItemId,
          })
        ];
      setScanInprogressState(prevInputtingCounterInfo);
    },
    [
      inputtingInspectionCount,
      inputtingItemId,
      inputtingSkuId,
      setScanInprogressState,
    ]
  );

  useEffect(() => {
    initCounter({ counter, receivingItemList: items });

    // counter를 넣을 수 없음
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, initCounter]);

  useEffect(() => {
    if (skuInProgress) {
      setCanNotLeavePage(true);
    } else {
      setCanNotLeavePage(false);
    }
  }, [skuInProgress, setCanNotLeavePage]);

  useEffect(() => {
    if (skuInProgress) {
      const target = counter.counterInfo[skuInProgress.counterKey];
      setSkuInProgress(target);
    } else {
      setSkuInProgress(undefined);
    }
    // counter를 넣을 수 없음
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skuInProgress]);

  const getTotalCount = useCallback(
    (counter: CounterDataForInspection, list?: ReceivingItem[]) => {
      if (!list) return "";

      let sum = 0;
      let total = 0;

      Object.values(counter).forEach((v) => {
        sum += v.current || 0;
      });

      list.forEach((v) => {
        total += v.quantity || 0;
      });

      return `${sum + unverifiedCount} / ${total}`;
    },
    [unverifiedCount]
  );

  const totalCount = useMemo<string>(
    () => getTotalCount(counter.counterInfo, items),
    [getTotalCount, counter.counterInfo, items]
  );

  const reset = useCallback(
    (items: ReceivingItem[]) => {
      setSkuInProgress(undefined);

      initCounter({
        counter,
        receivingItemList: items,
        needReset: true,
      });
    },
    [counter, initCounter]
  );

  return {
    skuInProgress,
    setSkuInProgress,
    counter,
    totalCount,
    reset,
  };
}
