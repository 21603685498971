import { atom } from "recoil";

import {
  GET_GROUP_SKU_INVENTORY_LIST_REQ,
  GET_SINGLE_SKU_INVENTORY_LIST_REQ,
} from "../../../api-interfaces/boful-api/inventory";

/**
 * 재고 페이지에서 상태를 초기화하기 위한 키
 */
const TEMP_KEY = atom({
  key: "fulfillment/inventory/atoms/TEMP_KEY",
  default: 0,
});

const SEARCH_QUERY_PARAMS_FOR_SINGLE_SKU =
  atom<GET_SINGLE_SKU_INVENTORY_LIST_REQ>({
    key: "fulfillment/inventory/atoms/SEARCH_QUERY_PARAMS_FOR_SINGLE_SKU",
    default: {
      page: 0,
      perPage: 10,
      includeEmptyInventory: true,
    },
  });

const SEARCH_QUERY_PARAMS_FOR_GROUP_SKU =
  atom<GET_GROUP_SKU_INVENTORY_LIST_REQ>({
    key: "fulfillment/inventory/atoms/SEARCH_QUERY_PARAMS_FOR_GROUP_SKU",
    default: {
      page: 0,
      perPage: 10,
    },
  });

export default {
  TEMP_KEY,
  SEARCH_QUERY_PARAMS_FOR_SINGLE_SKU,
  SEARCH_QUERY_PARAMS_FOR_GROUP_SKU,
};
