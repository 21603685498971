import { memo } from "react";

import ChipWithClear from "@sellernote/_sds-v1/src/components/ChipWithClear";

function PersonInCharge({
  worker,
  isRemovable,
  openConfirmModal,
}: {
  worker: string | undefined;
  isRemovable: boolean;
  openConfirmModal: () => void;
}) {
  if (!worker) return null;

  return (
    <ChipWithClear
      label={worker}
      handleClear={isRemovable ? openConfirmModal : undefined}
      colorTheme={"dark"}
    />
  );
}

export default memo(PersonInCharge);
