import { memo } from "react";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";

function ResetCount({
  currentQty,
  openConfirmModal,
}: {
  currentQty: number | undefined;
  openConfirmModal: () => void;
}) {
  return (
    <Button
      theme="tertiary"
      label="초기화"
      size="small"
      handleClick={openConfirmModal}
      disabled={!currentQty}
    />
  );
}

export default memo(ResetCount);
