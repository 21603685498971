import { memo } from "react";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";

function ResetCounting({
  canReset,
  openConfirmModal,
}: {
  canReset: boolean;
  openConfirmModal: () => void;
}) {
  return (
    <Button
      label="초기화"
      size="small"
      theme="tertiary"
      handleClick={(e) => {
        e.stopPropagation();
        openConfirmModal();
      }}
      disabled={!canReset}
    />
  );
}

export default memo(ResetCounting);
