import { memo } from "react";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";

function RemovePartialItem({
  openConfirmModal,
}: {
  openConfirmModal: () => void;
}) {
  return (
    <Button
      label="삭제"
      size="small"
      theme="dangerStroke"
      handleClick={openConfirmModal}
    />
  );
}

export default memo(RemovePartialItem);
