import { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";

/**
 * useSKUCountingForInspection 등 카운터 훅에서 생성된 inputting 데이터를 초기화하는 훅
 * 참고) https://sellernotehq.slack.com/archives/C062BQ9F1CL/p1711083171930589
 */
export default function useResetInputtingCounterInfo() {
  const history = useHistory();

  const location = useLocation();

  return useCallback(() => {
    history.replace(location.pathname);
  }, [history, location.pathname]);
}
